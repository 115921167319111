<template>
  <div id="app">
    <router-view></router-view> 
    <vue-progress-bar></vue-progress-bar>
    <vue-snotify></vue-snotify> 
    <page-footer></page-footer>
  </div>
</template>
<script>
import pageFooter from '@/components/pageFooter/page-footer';

export default {
  components:{
    pageFooter
  }
}
</script>
<style lang="scss">
@import "~vue-snotify/styles/material.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
