import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
// import BootstrapVue from 'bootstrap-vue'
import VueSnotify from 'vue-snotify';
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import ProgressBar from 'vue-progressbar'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import BootstrapVue from 'bootstrap-vue'
import VuePaginate from 'vue-paginate'
import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VuePaginate)
Vue.use(BootstrapVue)
Vue.use(VueFormWizard)
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

library.add(faUserSecret)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Vue.use(BootstrapVue)

// Install BootstrapVue
//Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)
Vue.use(ProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '2px'
})
Vue.use(VueSnotify, { toast: { showProgressBar: false } })
Vue.use(VueConfirmDialog)


Vue.config.productionTip = false;
sessionStorage.setItem('schoolid', 'FCBCOLLEGE');
sessionStorage.setItem('schoolname', 'FC BEAUTY COLLEGE');
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
