<template>
  <div id="landingPage">
    <navigation></navigation>
    <home></home>
    <divider></divider>
    <feature></feature>
    <pricing></pricing>
    <download></download>
    <contact></contact>
    <!-- <page-footer></page-footer> -->
  </div>
</template>
<script>
import navigation from '@/components/navigation/navigation';
import home from '@/components/home/home';
import divider from '@/components/divider/divider';
import feature from '@/components/feature/feature';
import pricing from '@/components/pricing/pricing';
import download from '@/components/download/download';
import contact from '@/components/contact/contact';
export default {
  components:{
    navigation,
    home,
    divider,
    feature,
    pricing,
    download,
    contact
  }
}
</script>
