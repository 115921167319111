import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/landingPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/applicant",
    name: "Admit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/joinPage.vue")
  },
  {
    path: "/student",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/logPage.vue")
  },
  {
    path: "/diplomaCourses",
    name: "Diploma",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/diplomaCourses.vue")
  },
  {
    path: "/advanceCourses",
    name: "Advance",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/advanceCourses.vue")
  },
  {
    path: "/awardCourses",
    name: "Award",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/awardCourses.vue")
  },
  {
    path: "/faqs",
    name: "FAQs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FAQs.vue")
  },
  {
    path: "/apply",
    name: "Apply",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/applicationPage.vue")
  },
  {
    path: "/studentLogin",
    name: "studentLogin",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/studentPage.vue")
  },
  { path: '*', component: Home }
];

const router = new VueRouter({
  routes
});

export default router;
